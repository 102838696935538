import React, { useState } from "react"
import { useAuth } from "../../../../auth"
import { useIntl } from "react-intl"
import * as Yup from "yup"
import { API_GET_PAYS_INDICATIFS_TEL_URL, CONST_CIVILITE_NON_RENSEIGNEE, CONST_CIVILITES_OPTIONS_FOR_SELECT2, CONST_DEFAULT_TOAST_OPTIONS } from "../../../../../constants"
import { useFormik } from "formik"
import { ProfilDetailsAfterDemandeEmbaucheFrelanceModel, ProfilDetailsAfterDemandeEmbaucheSousTraitantSchemaModel } from "../../../core/_models"
import MySelectField from "../../../../../utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "../../../../../utils/fields/MySelectGetAllDataFromServer"
import MyInptuMaskField from "../../../../../utils/fields/MyInputMaskField"
import { removePhotoProfil, setInfoPerso, setPhotoProfil } from "../../../core/_requests"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import { getThumbnailDriveUrl } from "../../../../../helpers/helper-global"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { ConsultantModel } from "../../../../../models"

const CompteInfoPersoEditPreembaucheSousTraitant = ({ onSubmitCallback }: IPropsModel) => {
     const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
     const [loadingCodePostal, setLoadingCodePostal] = useState<boolean>(false)
     const { currentUser, setCurrentUser } = useAuth()
     const intl = useIntl()
     const MySwal = withReactContent(Swal)

     const profileDetailsSchema = Yup.object().shape({
          civilite: Yup.string().required("Ce champ est requis."),
          prenom: Yup.string().required("Ce champ est requis."),
          nom: Yup.string().required("Ce champ est requis."),
          telIndicatifId: Yup.number().label("Indicatif téléphonique").required("L'indicatif est requis"),
          tel: Yup.string().required("Le tél. est requis"),
     })

     const formik = useFormik<ProfilDetailsAfterDemandeEmbaucheSousTraitantSchemaModel>({
          initialValues: {
               civilite: currentUser?.consultant?.consultant?.civilite,
               prenom: currentUser?.consultant?.consultant?.prenom || "",
               nom: currentUser?.consultant?.consultant?.nom || "",
               telIndicatifId: currentUser?.consultant?.consultant?.telProPays?.id,
               tel: currentUser?.consultant?.consultant?.telPro,
          },
          validationSchema: profileDetailsSchema,
          onSubmit: async (values, { setStatus }) => {
               setIsSubmitting(true)
               setStatus(false)

               const details: ProfilDetailsAfterDemandeEmbaucheFrelanceModel = {}
               if (values.civilite) details.civilite = values.civilite
               if (values.nom) details.nom = values.nom
               if (values.prenom) details.prenom = values.prenom
               if (values.telIndicatifId) details.telIndicatifId = values.telIndicatifId
               if (values.tel) details.tel = values.tel

               setInfoPerso(details)
                    .then(r => {
                         const consultant = r.data as ConsultantModel
                         setCurrentUser(prev => ({ ...prev, consultant: { ...prev?.consultant, consultant: { ...prev?.consultant?.consultant, ...consultant } } }))
                         toast.success("Vos modifications ont bien été prises en compte.", CONST_DEFAULT_TOAST_OPTIONS)
                         if (onSubmitCallback) onSubmitCallback()
                    })
                    .catch((e: AxiosError) => {
                         setStatus(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                    })
                    .finally(() => {
                         setIsSubmitting(false)
                    })
          },
     })

     function handleSetPhotoProfil(e) {
          const fileBinary = e.target.files[0]

          if (fileBinary) {
               toast.info(currentUser?.user?.avatarGoogleDriveId ? "Changement de votre avatar en cours ..." : "Ajout de votre avatar en cours ...", { autoClose: false })
               setPhotoProfil(fileBinary)
                    .then(r => {
                         setCurrentUser(prev => (prev ? { ...prev, user: prev.user ? { ...prev.user, avatarGoogleDriveId: r.data.id } : undefined } : prev))
                         toast.dismiss()
                         toast.success("Votre nouveau avatar a bien été pris en compte.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                    })
          }
     }

     function handleRemovePhotoProfil() {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir supprimer votre avatar?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return removePhotoProfil()
                         .then(() => {
                              setCurrentUser(prev => (prev ? { ...prev, user: { ...prev.user, avatarGoogleDriveId: null } } : prev))
                              toast.dismiss()
                              toast.success("Votre avatar a bien été supprimé ...")
                         })
                         .catch((e: AxiosError) => {
                              toast.dismiss()
                              toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return (
          <form onSubmit={formik.handleSubmit} noValidate className="form">
               {formik.status && (
                    <div className="alert alert-danger">
                         <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
               )}
               {/* Avatar */}
               <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
                    <div className="col-lg-8">
                         <div
                              className="image-input image-input-outline"
                              data-kt-image-input="true"
                              style={{ backgroundImage: `url(${toAbsoluteUrl("/media/avatars/blank.png")})` }}
                         >
                              <div
                                   className="image-input-wrapper w-125px h-125px"
                                   style={{
                                        backgroundImage: `url(${
                                             currentUser?.user?.avatarGoogleDriveId
                                                  ? getThumbnailDriveUrl(currentUser.user.avatarGoogleDriveId)
                                                  : toAbsoluteUrl("/media/avatars/blank.png")
                                        })`,
                                   }}
                              ></div>

                              <label
                                   className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                   data-kt-image-input-action="change"
                                   data-bs-original-title="Change avatar"
                              >
                                   <i className="bi bi-pencil-fill fs-7"></i>
                                   <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={e => handleSetPhotoProfil(e)} />
                              </label>

                              {currentUser?.user?.avatarGoogleDriveId && (
                                   <span
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                        data-kt-image-input-action="remove"
                                        data-bs-original-title="Remove avatar"
                                        onClick={() => handleRemovePhotoProfil()}
                                   >
                                        <i className="bi bi-x fs-2"></i>
                                   </span>
                              )}
                         </div>
                    </div>
               </div>

               <div className="pb-6">
                    {/*Civilité*/}
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(item => item.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                   name={"civilite"}
                                   value={formik.values?.civilite}
                                   isSearchable={false}
                                   onChange={value => formik.setFieldValue("civilite", value)}
                                   placeholder={"Choisissez une civilité"}
                                   isClearable={true}
                              />
                              {formik.touched.civilite && formik.errors.civilite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.civilite}</div>
                                   </div>
                              )}
                         </div>
                    </div>

                    {/*Nom & prénom*/}
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label required fw-bold fs-6">Prénom NOM</label>

                         <div className="col-lg-8">
                              <div className="row">
                                   <div className="col-lg-6 fv-row">
                                        <input
                                             type="text"
                                             className="form-control form-control-lg mb-3 mb-lg-0"
                                             placeholder="Prénom"
                                             {...formik.getFieldProps("prenom")}
                                             autoComplete={"off"}
                                        />
                                        {formik.touched.prenom && formik.errors.prenom && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.prenom}</div>
                                             </div>
                                        )}
                                   </div>

                                   <div className="col-lg-6 fv-row">
                                        <input type="text" className="form-control form-control-lg " placeholder="NOM" {...formik.getFieldProps("nom")} />
                                        {formik.touched.nom && formik.errors.nom && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.nom}</div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>

                    {/*Tél*/}
                    <div className="row pb-4">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                         <div className="col-lg-8">
                              <div className="row">
                                   <div className="col-lg-6 mb-lg-0 mb-3 fv-row">
                                        <MySelectGetAllDataFromServer
                                             url={API_GET_PAYS_INDICATIFS_TEL_URL}
                                             name={"telIndicatifId"}
                                             value={formik.values.telIndicatifId}
                                             defaultOptions={
                                                  currentUser?.consultant?.consultant?.telProPays
                                                       ? [
                                                              {
                                                                   label: currentUser?.consultant?.consultant?.telProPays?.phoneCodeWithPlusPrefixAndCountry,
                                                                   value: currentUser?.consultant?.consultant?.telProPays?.id,
                                                              },
                                                         ]
                                                       : []
                                             }
                                             placeholder={"Choisissez un indicatif"}
                                             onChange={val => formik.setFieldValue("telIndicatifId", val)}
                                        />
                                        {formik.errors.telIndicatifId && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.telIndicatifId}</div>
                                             </div>
                                        )}
                                   </div>

                                   <div className="col-lg-6 fv-row">
                                        <MyInptuMaskField
                                             name={"tel"}
                                             mask={"99 999999999999999999"}
                                             value={formik.values.tel}
                                             placeholder={"N° de téléphone"}
                                             inputMode={"numeric"}
                                             onChange={e => formik.setFieldValue("tel", e.target.value)}
                                             isInvalid={!!formik.errors.tel}
                                        />
                                        {formik.touched.tel && formik.errors.tel && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.tel}</div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-sm btn-primary" disabled={isSubmitting}>
                         {!isSubmitting && "Enregister les modifications"}
                         {isSubmitting && (
                              <span className="indicator-progress" style={{ display: "block" }}>
                                   Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                         )}
                    </button>
               </div>
          </form>
     )
}

interface IPropsModel {
     onSubmitCallback?: () => void
}

export default CompteInfoPersoEditPreembaucheSousTraitant
