import React, { useState } from "react"
import { useAuth } from "../../../auth"
import { CONST_CONSULTANT_STATUT_ST_FREELANCE, CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE, CONST_USER_ROLE_CONSULTANT } from "../../../../constants"
import DocumentsPerso from "./DocumentsPerso"
import DocumentsPro from "./pro/DocumentsPro"

export function Documents() {
     const { currentUser } = useAuth()

     const [activeOnglet, setActiveOnglet] = useState<"perso" | "pro">("perso")

     const isOngletProAccessible =
          currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) ||
          currentUser?.user?.roles?.includes("ROLE_FOURNISSEUR") ||
          (currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && currentUser?.consultant?.consultant?.statut === CONST_CONSULTANT_STATUT_ST_FREELANCE)

     return (
          <div className="card mb-2">
               <div className="card-header card-header-stretch">
                    <div className="card-title m-0">
                         <h3 className="fw-bolder m-0">Documents</h3>
                    </div>
                    <div className="card-toolbar">
                         <ul className="nav nav-tabs nav-line-tabs nav-stretch border-transparent fs-5 fw-bolder" id="kt_security_summary_tabs">
                              <li className="nav-item cursor-pointer" onClick={() => setActiveOnglet("perso")}>
                                   <span className={`nav-link text-active-primary ${activeOnglet == "perso" && "active"}`}>Perso.</span>
                              </li>
                              {/* Accessible uniquement aux consultants, aux candidats en cours d'embauche pour poste Freelance et aux fournisseurs*/}
                              {isOngletProAccessible && (
                                   <li className="nav-item cursor-pointer" onClick={() => setActiveOnglet("pro")}>
                                        <span className={`nav-link text-active-primary ${activeOnglet == "pro" && "active"}`}>Pro.</span>
                                   </li>
                              )}
                         </ul>
                    </div>
               </div>

               <div className="card-body">
                    {activeOnglet == "perso" && <DocumentsPerso />}
                    {activeOnglet == "pro" && isOngletProAccessible && <DocumentsPro />}
               </div>
          </div>
     )
}
