import { useNavigate } from "react-router-dom"
import React, { useContext } from "react"
import { useAuth } from "../../../../auth"
import CompteInfoPersoEditCandidat from "./CompteInfoPersoEditCandidat"
import CompteInfoPersoEditPreembaucheSousTraitant from "./CompteInfoPersoEditPreembaucheSousTraitant"
import CompteInfoPersoEditPreembaucheSalarie from "./CompteInfoPersoEditPreembaucheSalarie"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_USER_ROLE_CANDIDAT, CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE, CONST_USER_ROLE_CONSULTANT } from "../../../../../constants"
import CompteInfoPersoEditConsultantSousTraitant from "./CompteInfoPersoEditConsultantSousTraitant"
import CompteInfoPersoEditConsultantSalarie from "./CompteInfoPersoEditConsultantSalarie"
import { CompteInfoPersoContext } from "../CompteInfoPerso"

export function CompteInfoPersoEdit() {
     const { currentUser } = useAuth()
     const navigate = useNavigate()
     const { toggleToPreviewMode } = useContext(CompteInfoPersoContext)

     return (
          <>
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && <CompteInfoPersoEditCandidat />}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && currentUser?.consultant?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST && (
                    <CompteInfoPersoEditPreembaucheSousTraitant onSubmitCallback={toggleToPreviewMode} />
               )}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && currentUser?.consultant?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                    <CompteInfoPersoEditPreembaucheSalarie onSubmitCallback={toggleToPreviewMode} />
               )}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && currentUser?.consultant?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST && (
                    <CompteInfoPersoEditConsultantSousTraitant onSubmitCallback={toggleToPreviewMode} />
               )}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && currentUser?.consultant?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                    <CompteInfoPersoEditConsultantSalarie onSubmitCallback={toggleToPreviewMode} />
               )}
          </>
     )
}
