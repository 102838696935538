import React, { useRef, useState } from "react"
import {
     API_URL_ESPACE_AVENTURIER,
     CONST_CANDIDAT_DOCUMENT_TYPE_CV,
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
     CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES,
     CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME,
     CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI,
     CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE,
     CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
     CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_PAYS_CODE_FRANCE,
} from "../../../../constants"
import { useAuth } from "../../../auth"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import { useIntl } from "react-intl"
import { CandidatDocumentModel, ConsultantDocumentModel } from "../../../../models"
import { toast } from "react-toastify"
import { addDocumentEmbauche, removeDocumentEmbauche, setDocumentEmbauche } from "../../core/_requests"
import axios, { AxiosError } from "axios"
import { getExtensionAccordingToMimetype } from "../../../../helpers/helper-global"
import MyModal from "../../../../utils/MyModal"

function DocumentsPerso() {
     const [isModalViewDocoShown, setIsModalViewDocoShown] = useState<{ isShown: boolean; googleDriveId: string; titre: string }>({
          isShown: false,
          googleDriveId: "",
          titre: "",
     })

     const refsInputRemplacerDocument = {}
     refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_CV] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_RIB] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME] = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)]
     refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES] = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)]

     const { currentUser, setCurrentUser } = useAuth()
     const MySwal = withReactContent(Swal)
     const intl = useIntl()

     let document_type_cv: CandidatDocumentModel | null = null
     let document_type_passeport_cni: ConsultantDocumentModel | null = null
     let document_type_titre_sejour: ConsultantDocumentModel | null = null
     let document_type_permis_conduire: ConsultantDocumentModel | null = null
     let document_type_attestation_ss: ConsultantDocumentModel | null = null
     let document_type_rib: ConsultantDocumentModel | null = null
     let documents_type_diplome: ConsultantDocumentModel[] | null = null
     let documents_type_autres: ConsultantDocumentModel[] | null = null

     currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments?.map(item => {
          switch (item.type) {
               case CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI:
                    document_type_passeport_cni = item
                    break
               case CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR:
                    document_type_titre_sejour = item
                    break
               case CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS:
                    document_type_attestation_ss = item
                    break
               case CONST_CONSULTANT_DOCUMENT_TYPE_RIB:
                    document_type_rib = item
                    break
               case CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE:
                    document_type_permis_conduire = item
                    break
               case CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME:
                    if (documents_type_diplome) {
                         documents_type_diplome.push(item)
                    } else {
                         documents_type_diplome = [item]
                    }
                    break
               case CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES:
                    if (documents_type_autres) {
                         documents_type_autres.push(item)
                    } else {
                         documents_type_autres = [item]
                    }
                    break
          }
     })

     currentUser?.candidat?.candidat?.undeletedCandidatDocuments?.map(item => {
          switch (item.type) {
               case CONST_CANDIDAT_DOCUMENT_TYPE_CV:
                    document_type_cv = item
                    break
          }
     })

     function handleAddDocument(e, type) {
          const fileBinary = e.target.files[0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info(`Envoi du document en cours ...`, { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })

               addDocumentEmbauche(fileBinary, type)
                    .then(r => {
                         setCurrentUser(prev => {
                              if (prev) {
                                   if (prev.candidat?.candidat?.undeletedCandidatDocuments) prev.candidat.candidat.undeletedCandidatDocuments = r.data.candidatDocuments
                                   if (prev.consultant?.consultant?.undeletedAndSigneConsultantDocuments)
                                        prev.consultant.consultant.undeletedAndSigneConsultantDocuments = r.data.consultantDocuments

                                   return { ...prev }
                              }

                              return undefined
                         })
                         toast.dismiss()
                         toast.success("Le document a bien été déposé. Vous pouvez le modifier ou supprimer si besoin.", CONST_DEFAULT_TOAST_OPTIONS)
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(
                              e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }),
                              CONST_DEFAULT_TOAST_OPTIONS
                         )
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function handleSetDocument(e, reference: string) {
          const fileBinary = e.target.files[0]

          if (fileBinary) {
               e.target.disabled = true
               toast.info("Remplacement du document en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })

               setDocumentEmbauche(fileBinary, reference)
                    .then(r => {
                         setCurrentUser(prev => {
                              if (prev) {
                                   if (prev.candidat?.candidat) prev.candidat.candidat.undeletedCandidatDocuments = r.data.candidatDocuments
                                   if (prev.consultant?.consultant) {
                                        prev.consultant.consultant.undeletedAndSigneConsultantDocuments = r.data.consultantDocuments
                                   }

                                   return { ...prev }
                              }

                              return undefined
                         })
                         toast.dismiss()
                         toast.success("Le document a bien été remplacé.", CONST_DEFAULT_TOAST_OPTIONS)
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(
                              e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }),
                              CONST_DEFAULT_TOAST_OPTIONS
                         )
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function handleRemoveDocument(event, reference: string) {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir supprimer le document?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return removeDocumentEmbauche(reference)
                         .then(r => {
                              setCurrentUser(prev => {
                                   if (prev) {
                                        if (prev.candidat?.candidat?.undeletedCandidatDocuments) prev.candidat.candidat.undeletedCandidatDocuments = r.data.candidatDocuments
                                        if (prev.consultant?.consultant?.undeletedAndSigneConsultantDocuments)
                                             prev.consultant.consultant.undeletedAndSigneConsultantDocuments = r.data.consultantDocuments

                                        return { ...prev }
                                   }

                                   return undefined
                              })
                              toast.success("Le document a bien été supprimé.", CONST_DEFAULT_TOAST_OPTIONS)
                         })
                         .catch((e: AxiosError) => {
                              toast.error(
                                   e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }),
                                   CONST_DEFAULT_TOAST_OPTIONS
                              )
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function handleDownload(e, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Téléchargement du document en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference },
               {
                    responseType: "blob",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    const link = document.createElement("a")
                    link.href = url
                    link.setAttribute("target", "_blank")
                    link.setAttribute("download", `document.${getExtensionAccordingToMimetype(mimeType)}`)
                    document.body.appendChild(link)
                    link.click()

                    toast.dismiss()
                    toast.success("Votre document a bien été téléchargé", CONST_DEFAULT_TOAST_OPTIONS)
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     return (
          <>
               {/* CV (Tout espace) */}
               <div className={`row mb-4`}>
                    <label className="col-md-4 col-form-label fw-bold fs-6">
                         <span className={"me-2"}>CV</span>
                         {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                              <> {document_type_cv ? <i className={"fas fa-check-circle text-success"} /> : <i className={"fas fa-exclamation-circle text-warning"} />} </>
                         )}
                    </label>
                    <div className="col-md-8">
                         {document_type_cv ? (
                              <>
                                   <button
                                        className={"btn btn-sm btn-warning me-2 text-black"}
                                        onClick={() => refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_CV]?.current?.click()}
                                   >
                                        Remplacer
                                   </button>
                                   <input
                                        type="file"
                                        accept={"application/pdf, image/*"}
                                        className={"d-none"}
                                        ref={refsInputRemplacerDocument[CONST_CANDIDAT_DOCUMENT_TYPE_CV]}
                                        onChange={e => handleSetDocument(e, document_type_cv!.googleDriveID)}
                                   />
                                   <button
                                        className={"btn btn-sm btn-primary me-2"}
                                        onClick={e => handleDownload(e, document_type_cv!.googleDriveID, document_type_cv!.googleDriveMimeType)}
                                   >
                                        Télécharger
                                   </button>
                                   <button className={"btn btn-sm btn-danger"} onClick={e => handleRemoveDocument(e, document_type_cv!.googleDriveID)}>
                                        <i className={"fas fa-trash p-0"} />
                                   </button>
                              </>
                         ) : (
                              <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_CANDIDAT_DOCUMENT_TYPE_CV)} />
                         )}
                    </div>
               </div>

               {currentUser?.consultant && (
                    <>
                         {currentUser?.consultant?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                              <>
                                   {/*Passport / cni */}
                                   <div className={`row mb-4`}>
                                        <label className="col-md-4 col-form-label fw-bold fs-6">
                                             <span className={"me-2"}>Passeport ou carte d'identité</span>
                                             {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                  <>
                                                       {" "}
                                                       {document_type_passeport_cni ? (
                                                            <i className={"fas fa-check-circle text-success"} />
                                                       ) : (
                                                            <i className={"fas fa-exclamation-circle text-warning"} />
                                                       )}{" "}
                                                  </>
                                             )}
                                        </label>
                                        <div className="col-md-8">
                                             {document_type_passeport_cni ? (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-2 text-black"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI]?.current?.click()}
                                                       >
                                                            Remplacer
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI]}
                                                            onChange={e => handleSetDocument(e, document_type_passeport_cni!.googleDriveID)}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-2"}
                                                            onClick={e =>
                                                                 handleDownload(e, document_type_passeport_cni!.googleDriveID, document_type_passeport_cni!.googleDriveMimeType)
                                                            }
                                                       >
                                                            Télécharger
                                                       </button>
                                                       {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                            <button
                                                                 className={"btn btn-sm btn-danger"}
                                                                 onClick={e => handleRemoveDocument(e, document_type_passeport_cni!.googleDriveID)}
                                                            >
                                                                 <i className={"fas fa-trash p-0"} />
                                                            </button>
                                                       )}
                                                  </>
                                             ) : (
                                                  <input
                                                       type={"file"}
                                                       className={"form-control"}
                                                       onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)}
                                                  />
                                             )}
                                        </div>
                                   </div>

                                   {/*Carte de séjour (si étranger)*/}
                                   {currentUser?.consultant?.consultant?.nationalite && currentUser?.consultant?.consultant?.nationalite?.code != CONST_PAYS_CODE_FRANCE && (
                                        <div className="row mb-4">
                                             <label className="col-md-4 col-form-label fw-bold fs-6">
                                                  <span className={"me-2"}>Titre de séjour</span>
                                                  {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                       <>
                                                            {document_type_titre_sejour ? (
                                                                 <i className={"fas fa-check-circle text-success"} />
                                                            ) : (
                                                                 <i className={"fas fa-exclamation-circle text-warning"} />
                                                            )}{" "}
                                                       </>
                                                  )}
                                             </label>
                                             <div className="col-md-8">
                                                  {document_type_titre_sejour ? (
                                                       <>
                                                            <button
                                                                 className={"btn btn-sm btn-warning me-2 text-black"}
                                                                 onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR]?.current?.click()}
                                                            >
                                                                 Remplacer
                                                            </button>
                                                            <input
                                                                 type="file"
                                                                 accept={"application/pdf, image/*"}
                                                                 className={"d-none"}
                                                                 ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR]}
                                                                 onChange={e => handleSetDocument(e, document_type_titre_sejour!.googleDriveID)}
                                                            />
                                                            <button
                                                                 className={"btn btn-sm btn-primary me-2"}
                                                                 onClick={e =>
                                                                      handleDownload(e, document_type_titre_sejour!.googleDriveID, document_type_titre_sejour!.googleDriveMimeType)
                                                                 }
                                                            >
                                                                 Télécharger
                                                            </button>
                                                            {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                                 <button
                                                                      className={"btn btn-sm btn-danger"}
                                                                      onClick={e => handleRemoveDocument(e, document_type_titre_sejour!.googleDriveID)}
                                                                 >
                                                                      <i className={"fas fa-trash p-0"} />
                                                                 </button>
                                                            )}
                                                       </>
                                                  ) : (
                                                       <input
                                                            type={"file"}
                                                            accept={"application/pdf, image/*"}
                                                            className={"form-control"}
                                                            onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)}
                                                       />
                                                  )}
                                             </div>
                                        </div>
                                   )}

                                   {/*Attestation de sécurité sociale*/}
                                   <div className="row mb-4">
                                        <label className="col-md-4 col-form-label fw-bold fs-6">
                                             <span className={"me-2"}>Attestation de sécurité sociale</span>
                                             {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                  <>
                                                       {" "}
                                                       {document_type_attestation_ss ? (
                                                            <i className={"fas fa-check-circle text-success"} />
                                                       ) : (
                                                            <i className={"fas fa-exclamation-circle text-warning"} />
                                                       )}{" "}
                                                  </>
                                             )}
                                        </label>
                                        <div className="col-md-8">
                                             {!document_type_attestation_ss ? (
                                                  <input
                                                       type={"file"}
                                                       className={"form-control"}
                                                       onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)}
                                                  />
                                             ) : (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-2 text-black"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS]?.current?.click()}
                                                       >
                                                            Remplacer
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS]}
                                                            onChange={e => handleSetDocument(e, document_type_attestation_ss!.googleDriveID)}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-2"}
                                                            onClick={e =>
                                                                 handleDownload(e, document_type_attestation_ss!.googleDriveID, document_type_attestation_ss!.googleDriveMimeType)
                                                            }
                                                       >
                                                            Télécharger
                                                       </button>
                                                       {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                            <button
                                                                 className={"btn btn-sm btn-danger"}
                                                                 onClick={e => handleRemoveDocument(e, document_type_attestation_ss!.googleDriveID)}
                                                            >
                                                                 <i className={"fas fa-trash p-0"} />
                                                            </button>
                                                       )}
                                                  </>
                                             )}
                                        </div>
                                   </div>

                                   {/*RIB*/}
                                   <div className="row mb-4">
                                        <label className="col-md-4 col-form-label fw-bold fs-6">
                                             <span className={"me-2"}>Relevé d'identité bancaire (RIB)</span>
                                             {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                  <>
                                                       {" "}
                                                       {document_type_rib ? (
                                                            <i className={"fas fa-check-circle text-success"} />
                                                       ) : (
                                                            <i className={"fas fa-exclamation-circle text-warning"} />
                                                       )}{" "}
                                                  </>
                                             )}
                                        </label>
                                        <div className="col-md-8">
                                             {!document_type_rib ? (
                                                  <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_RIB)} />
                                             ) : (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-2 text-black"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_RIB]?.current?.click()}
                                                       >
                                                            Remplacer
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_RIB]}
                                                            onChange={e => handleSetDocument(e, document_type_rib!.googleDriveID)}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-2"}
                                                            onClick={e => handleDownload(e, document_type_rib!.googleDriveID, document_type_rib!.googleDriveMimeType)}
                                                       >
                                                            Télécharger
                                                       </button>
                                                       {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                            <button className={"btn btn-sm btn-danger"} onClick={e => handleRemoveDocument(e, document_type_rib!.googleDriveID)}>
                                                                 <i className={"fas fa-trash p-0"} />
                                                            </button>
                                                       )}
                                                  </>
                                             )}
                                        </div>
                                   </div>

                                   {/*Permis de conduire*/}
                                   <div className="row mb-4">
                                        <label className="col-md-4 col-form-label fw-bold fs-6">
                                             <span className={"me-2"}>Permis de conduire</span>
                                             {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                  <>
                                                       {" "}
                                                       {document_type_permis_conduire ? (
                                                            <i className={"fas fa-check-circle text-success"} />
                                                       ) : (
                                                            <i className={"fas fa-exclamation-circle text-warning"} />
                                                       )}{" "}
                                                  </>
                                             )}
                                        </label>
                                        <div className="col-md-8">
                                             {!document_type_permis_conduire ? (
                                                  <input
                                                       type={"file"}
                                                       className={"form-control"}
                                                       onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)}
                                                  />
                                             ) : (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-2 text-black"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE]?.current?.click()}
                                                       >
                                                            Remplacer
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE]}
                                                            onChange={e => handleSetDocument(e, document_type_permis_conduire!.googleDriveID)}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-2"}
                                                            onClick={e =>
                                                                 handleDownload(e, document_type_permis_conduire!.googleDriveID, document_type_permis_conduire!.googleDriveMimeType)
                                                            }
                                                       >
                                                            Télécharger
                                                       </button>
                                                       {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                                            <button
                                                                 className={"btn btn-sm btn-danger"}
                                                                 onClick={e => handleRemoveDocument(e, document_type_permis_conduire!.googleDriveID)}
                                                            >
                                                                 <i className={"fas fa-trash p-0"} />
                                                            </button>
                                                       )}
                                                  </>
                                             )}
                                        </div>
                                   </div>

                                   <h4 className={"mt-8 mb-4"}>Diplômes</h4>
                                   <div className="separator separator-dashed mb-6"></div>

                                   {/*Diplôme 1*/}
                                   <div className="row mb-4">
                                        <label className="col-md-4 col-form-label fw-bold fs-6">Diplôme 1</label>
                                        <div className="col-md-8">
                                             {Array.isArray(documents_type_diplome) && typeof documents_type_diplome[0] !== "undefined" ? (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-2 text-black"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][0]?.current?.click()}
                                                       >
                                                            Remplacer
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][0]}
                                                            onChange={e => handleSetDocument(e, documents_type_diplome![0].googleDriveID)}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-2"}
                                                            onClick={e =>
                                                                 handleDownload(e, documents_type_diplome![0].googleDriveID, documents_type_diplome![0].googleDriveMimeType)
                                                            }
                                                       >
                                                            Télécharger
                                                       </button>
                                                       <button className={"btn btn-sm btn-danger"} onClick={e => handleRemoveDocument(e, documents_type_diplome![0].googleDriveID)}>
                                                            <i className={"fas fa-trash p-0"} />
                                                       </button>
                                                  </>
                                             ) : (
                                                  <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)} />
                                             )}
                                        </div>
                                   </div>

                                   {/*Diplôme 2*/}
                                   <div className="row mb-4">
                                        <label className="col-md-4 col-form-label fw-bold fs-6">Diplôme 2</label>
                                        <div className="col-md-8">
                                             {Array.isArray(documents_type_diplome) && typeof documents_type_diplome[1] !== "undefined" ? (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-2 text-black"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][1]?.current?.click()}
                                                       >
                                                            Remplacer
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][1]}
                                                            onChange={e => handleSetDocument(e, documents_type_diplome![1].googleDriveID)}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-2"}
                                                            onClick={e =>
                                                                 handleDownload(e, documents_type_diplome![1].googleDriveID, documents_type_diplome![1].googleDriveMimeType)
                                                            }
                                                       >
                                                            Télécharger
                                                       </button>
                                                       <button className={"btn btn-sm btn-danger"} onClick={e => handleRemoveDocument(e, documents_type_diplome![1].googleDriveID)}>
                                                            <i className={"fas fa-trash p-0"} />
                                                       </button>
                                                  </>
                                             ) : (
                                                  <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)} />
                                             )}
                                        </div>
                                   </div>

                                   {/*Diplôme 3*/}
                                   <div className="row mb-4">
                                        <label className="col-md-4 col-form-label fw-bold fs-6">Diplôme 3</label>
                                        <div className="col-md-8">
                                             {Array.isArray(documents_type_diplome) && typeof documents_type_diplome[2] !== "undefined" ? (
                                                  <>
                                                       <button
                                                            className={"btn btn-sm btn-warning me-2 text-black"}
                                                            onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][2]?.current?.click()}
                                                       >
                                                            Remplacer
                                                       </button>
                                                       <input
                                                            type="file"
                                                            accept={"application/pdf, image/*"}
                                                            className={"d-none"}
                                                            ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME][2]}
                                                            onChange={e => handleSetDocument(e, documents_type_diplome![2].googleDriveID)}
                                                       />
                                                       <button
                                                            className={"btn btn-sm btn-primary me-2"}
                                                            onClick={e =>
                                                                 handleDownload(e, documents_type_diplome![2].googleDriveID, documents_type_diplome![2].googleDriveMimeType)
                                                            }
                                                       >
                                                            Télécharger
                                                       </button>
                                                       <button className={"btn btn-sm btn-danger"} onClick={e => handleRemoveDocument(e, documents_type_diplome![2].googleDriveID)}>
                                                            <i className={"fas fa-trash p-0"} />
                                                       </button>
                                                  </>
                                             ) : (
                                                  <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME)} />
                                             )}
                                        </div>
                                   </div>
                              </>
                         )}

                         <h4 className={"mt-8 mb-4"}>Autres</h4>
                         <div className="separator separator-dashed mb-6"></div>

                         {/*Autre 1*/}
                         <div className="row mb-4">
                              <label className="col-md-4 col-form-label fw-bold fs-6">Autre 1</label>
                              <div className="col-md-8">
                                   {Array.isArray(documents_type_autres) && typeof documents_type_autres[0] !== "undefined" ? (
                                        <>
                                             <button
                                                  className={"btn btn-sm btn-warning me-2 text-black"}
                                                  onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][0]?.current?.click()}
                                             >
                                                  Remplacer
                                             </button>
                                             <input
                                                  type="file"
                                                  accept={"application/pdf, image/*"}
                                                  className={"d-none"}
                                                  ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][0]}
                                                  onChange={e => handleSetDocument(e, documents_type_autres![0].googleDriveID)}
                                             />
                                             <button
                                                  className={"btn btn-sm btn-primary me-2"}
                                                  onClick={e => handleDownload(e, documents_type_autres![0].googleDriveID, documents_type_autres![0].googleDriveMimeType)}
                                             >
                                                  Télécharger
                                             </button>
                                             <button className={"btn btn-sm btn-danger"} onClick={e => handleRemoveDocument(e, documents_type_autres![0].googleDriveID)}>
                                                  <i className={"fas fa-trash p-0"} />
                                             </button>
                                        </>
                                   ) : (
                                        <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)} />
                                   )}
                              </div>
                         </div>

                         {/*Autre 2*/}
                         <div className="row mb-4">
                              <label className="col-md-4 col-form-label fw-bold fs-6">Autre 2</label>
                              <div className="col-md-8">
                                   {Array.isArray(documents_type_autres) && typeof documents_type_autres[1] !== "undefined" ? (
                                        <>
                                             <button
                                                  className={"btn btn-sm btn-warning me-2 text-black"}
                                                  onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][1]?.current?.click()}
                                             >
                                                  Remplacer
                                             </button>
                                             <input
                                                  type="file"
                                                  accept={"application/pdf, image/*"}
                                                  className={"d-none"}
                                                  ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][1]}
                                                  onChange={e => handleSetDocument(e, documents_type_autres![1].googleDriveID)}
                                             />
                                             <button
                                                  className={"btn btn-sm btn-primary me-2"}
                                                  onClick={e => handleDownload(e, documents_type_autres![1].googleDriveID, documents_type_autres![1].googleDriveMimeType)}
                                             >
                                                  Télécharger
                                             </button>
                                             <button className={"btn btn-sm btn-danger"} onClick={e => handleRemoveDocument(e, documents_type_autres![1].googleDriveID)}>
                                                  <i className={"fas fa-trash p-0"} />
                                             </button>
                                        </>
                                   ) : (
                                        <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)} />
                                   )}
                              </div>
                         </div>

                         {/*Autre 3*/}
                         <div className="row mb-4">
                              <label className="col-md-4 col-form-label fw-bold fs-6">Autre 3</label>
                              <div className="col-md-8">
                                   {Array.isArray(documents_type_autres) && typeof documents_type_autres[2] !== "undefined" ? (
                                        <>
                                             <button
                                                  className={"btn btn-sm btn-warning me-2 text-black"}
                                                  onClick={() => refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][2]?.current?.click()}
                                             >
                                                  Remplacer
                                             </button>
                                             <input
                                                  type="file"
                                                  accept={"application/pdf, image/*"}
                                                  className={"d-none"}
                                                  ref={refsInputRemplacerDocument[CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES][2]}
                                                  onChange={e => handleSetDocument(e, documents_type_autres![2].googleDriveID)}
                                             />
                                             <button
                                                  className={"btn btn-sm btn-primary me-2"}
                                                  onClick={e => handleDownload(e, documents_type_autres![2].googleDriveID, documents_type_autres![2].googleDriveMimeType)}
                                             >
                                                  Télécharger
                                             </button>
                                             <button className={"btn btn-sm btn-danger"} onClick={e => handleRemoveDocument(e, documents_type_autres![2].googleDriveID)}>
                                                  <i className={"fas fa-trash p-0"} />
                                             </button>
                                        </>
                                   ) : (
                                        <input type={"file"} className={"form-control"} onChange={e => handleAddDocument(e, CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES)} />
                                   )}
                              </div>
                         </div>
                    </>
               )}

               <MyModal
                    title={<>{isModalViewDocoShown.titre}</>}
                    show={isModalViewDocoShown.isShown}
                    fullscreen={true}
                    handleClose={() => setIsModalViewDocoShown({ googleDriveId: "", titre: "", isShown: false })}
               >
                    <ViewDoco id={isModalViewDocoShown.googleDriveId} />
               </MyModal>
          </>
     )
}

function ViewDoco({ id }: { id: string }) {
     const [loading, setLoading] = useState<boolean>(true)
     return (
          <>
               {loading && (
                    <div className={"text-center p-4"}>
                         <span className="spinner-border mb-2"></span>
                         <p style={{ fontWeight: 500 }}>Chargement du dossier en cours ...</p>
                    </div>
               )}
               <iframe
                    onLoad={() => setLoading(false)}
                    src={`https://docs.google.com/document/d/${id}/edit`}
                    frameBorder="0"
                    style={{ display: "block", width: "100%", height: "88vh" }}
               />
          </>
     )
}

export default DocumentsPerso
