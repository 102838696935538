import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import clsx from "clsx"
import { getUserByToken, resetPassword } from "../core/_requests"
import { AxiosError, AxiosResponse } from "axios"
import { useAuth } from "../core/Auth"
import MyAlert from "../../../utils/MyAlert"

const initialValues = {
     password: "",
     changepassword: "",
}

const registrationSchema = Yup.object().shape({
     password: Yup.string()
          .min(6, "Minimum 6 caractères")
          .max(50, "Maximum 50 caractères")
          .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Au mois un caractère spécial")
          .required("Le mot de passe est requis"),
     changepassword: Yup.string()
          .required("La confirmation du mot de passe est requise")
          .when("password", {
               is: (val: string) => (val && val.length > 0 ? true : false),
               then: schema => schema.oneOf([Yup.ref("password")], "Le mot de passe et la confirmation du mot de passe ne correspondent pas"),
          }),
})

const params = new URLSearchParams(window.location.search)

export function ResetPassword() {
     const token = params.get("token")
     const { saveAuth, setCurrentUser } = useAuth()

     const [loading, setLoading] = useState(false)
     const formik = useFormik({
          initialValues,
          validationSchema: registrationSchema,
          onSubmit: async (values, { setStatus }) => {
               setLoading(true)

               resetPassword(values.password, token || "")
                    .then(() => {
                         saveAuth({ token: token || "" })
                         getUserByToken(token || "")
                              .then((r: AxiosResponse) => {
                                   setCurrentUser(r.data)
                              })
                              .catch((r: AxiosError) => {
                                   if (r.response?.status === 400) {
                                        setStatus(r.response?.data?.message)
                                   } else {
                                        setStatus("Echec lors du changement de mot de passe. Veuillez réessayer.")
                                   }
                              })
                    })
                    .catch((r: AxiosError) => {
                         if (r.response?.status === 400) {
                              setStatus(r.response?.data?.message)
                         } else {
                              setStatus("Echec lors du changement de mot de passe. Veuillez réessayer.")
                         }
                         setLoading(false)
                    })
          },
     })

     return (
          <>
               <MyAlert type={"primary"} classNames={"mb-5"}>
                    Veuillez saisir votre nouveau mot de passe
               </MyAlert>
               <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate id="kt_login_signup_form" onSubmit={formik.handleSubmit}>
                    {formik.status && (
                         <div className="mb-lg-15 alert alert-danger">
                              <div className="alert-text font-weight-bold">{formik.status}</div>
                         </div>
                    )}

                    {/* begin::Form group Password */}
                    <div className="fv-row mb-8" data-kt-password-meter="true">
                         <div className="mb-1">
                              <label className="form-label fw-bolder text-black fs-6 required">Votre nouveau mot de passe</label>
                              <div className="position-relative mb-3">
                                   <input
                                        type="password"
                                        placeholder="Mot de passe"
                                        autoComplete="off"
                                        {...formik.getFieldProps("password")}
                                        className={clsx(
                                             "form-control bg-transparent",
                                             {
                                                  "is-invalid": formik.touched.password && formik.errors.password,
                                             },
                                             {
                                                  "is-valid": formik.touched.password && !formik.errors.password,
                                             }
                                        )}
                                   />
                                   {formik.touched.password && formik.errors.password && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">
                                                  <span role="alert">{formik.errors.password}</span>
                                             </div>
                                        </div>
                                   )}
                              </div>
                         </div>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group Confirm password */}
                    <div className="fv-row mb-5">
                         <label className="form-label fw-bolder text-black fs-6 required">Confirmez votre mot de passe</label>
                         <input
                              type="password"
                              placeholder="Confirmez le mot de passe"
                              autoComplete="off"
                              {...formik.getFieldProps("changepassword")}
                              className={clsx(
                                   "form-control bg-transparent",
                                   {
                                        "is-invalid": formik.touched.changepassword && formik.errors.changepassword,
                                   },
                                   {
                                        "is-valid": formik.touched.changepassword && !formik.errors.changepassword,
                                   }
                              )}
                         />
                         {formik.touched.changepassword && formik.errors.changepassword && (
                              <div className="fv-plugins-message-container">
                                   <div className="fv-help-block">
                                        <span role="alert">{formik.errors.changepassword}</span>
                                   </div>
                              </div>
                         )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="text-center">
                         <button type="submit" id="kt_sign_up_submit" className="btn btn-lg btn-primary w-100 mb-5" disabled={formik.isSubmitting || !formik.isValid}>
                              {!loading && <span className="indicator-label">Valider</span>}
                              {loading && (
                                   <span className="indicator-progress" style={{ display: "block" }}>
                                        Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                   </span>
                              )}
                         </button>
                    </div>
                    {/* end::Form group */}
               </form>
          </>
     )
}
