import axios from "axios"
import {AuthUserModel} from "../../auth"
import {ProfilDetailsAfterDemandeEmbaucheFrelanceModel, ProfilDetailsAfterDemandeEmbaucheSalarieModel, ProfilDetailsBeforeDemandeEmbaucheModel} from "./_models"
import {API_URL_ESPACE_AVENTURIER, API_URL_ESPACE_CANDIDAT, API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE, API_URL_ESPACE_CONSULTANT} from "../../../constants"
import {CandidatDocumentModel, ConsultantDocumentModel, ConsultantModel, ICandidatModel} from "../../../models"


/**
 * Permet de reset le password une fois logged-in
 * @param currentPassword
 * @param newPassword
 */
export function resetPasswordAfterLogin(currentPassword: string, newPassword: string) {
    return axios.put<AuthUserModel>(`${API_URL_ESPACE_AVENTURIER}/reset-password-after-login`, {
        currentPassword,
        newPassword,
    })
}

/**
 * Permet de générer le code 2FA côté serveur et l'envoyer par mail pour ensuite activer la 2FA
 */
export function enableTwoFactorAuthEmailGenerateCode() {
    return axios.put(`${API_URL_ESPACE_CONSULTANT}/enable-two-factor-auth-email-generate-code`, {})
}

/**
 * Permet d'activer la 2FA à l'aide du code 2FA envoyé par mail
 * @param password
 */
export function enableTwoFactorAuthEmailValidateCode(password: string) {
    return axios.put(`${API_URL_ESPACE_CONSULTANT}/enable-two-factor-auth-email-validate-code`, {
        password
    })
}

/**
 * Permet de générer le code 2FA côté serveur et l'envoyer par mail pour ensuite désactiver la 2FA
 */
export function disableTwoFactorAuthGenerateCode() {
    return axios.put(`${API_URL_ESPACE_CONSULTANT}/disable-two-factor-auth-generate-code`, {})
}

/**
 * Permet de désativer la 2FA à l'aide du code 2FA envoyé par mail
 * @param password
 */
export function disableTwoFactorAuthValidateCode(password: string) {
    return axios.put(`${API_URL_ESPACE_CONSULTANT}/disable-two-factor-auth-validate-code`, {
        password
    })
}

/**
 * Permet de générer un code côté serveur et l'envoyer par mail pour ensuite changer l'@ mail
 */
export function setEmailGenerateCode(mail: string, currentPassword: string) {
    return axios.put(`${API_URL_ESPACE_AVENTURIER}/set-mail-generate-code`, {
        currentPassword,
        mail
    })
}

/**
 * Permet de changer l'@ mail en confirmant le code
 * @param mail
 * @param currentPassword
 * @param code
 */
export function setEmailConfirmCode(mail: string, currentPassword: string, code: string) {
    return axios.put(`${API_URL_ESPACE_AVENTURIER}/set-mail-confirm-code`, {
        currentPassword,
        mail,
        code
    })
}

/**
 * Permet de générer un code côté serveur et l'envoyer par mail pour ensuite delete le compte
 */
export function deleteAccountGenerateCode() {
    return axios.put(`${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/delete-account-generate-code`, {})
}

/**
 * Permet de delete le compte en confirmant le code
 * @param currentPassword
 * @param code
 */
export function deleteAccountConfirmCode(currentPassword: string, code: string) {
    return axios.put(`${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/delete-account-confirm-code`, {
        currentPassword,
        code
    })
}

/**
 * Permet de changer les info de base de l'utilisateur
 * @param details
 */
export function setInfoPerso(details: ProfilDetailsAfterDemandeEmbaucheSalarieModel | ProfilDetailsAfterDemandeEmbaucheFrelanceModel | ProfilDetailsBeforeDemandeEmbaucheModel) {
    return axios.put<ICandidatModel|ConsultantModel>(`${API_URL_ESPACE_AVENTURIER}/set-info-perso`, details)
}

/**
 * Permet d'ajouter ou modifier le CV en fonction s'il existe ou pas
 * @param file
 */
export function setCurriculum(file: File) {
    const formData = new FormData();
    formData.append('fichier', file)
    return axios.post<CandidatDocumentModel>(`${API_URL_ESPACE_CANDIDAT}/set-curriculum`, formData)
}

export function addDocumentEmbauche(file: File, type: string) {
    const formData = new FormData();
    formData.append('fichier', file)
    formData.append('type', type)

    return axios.post<{ candidatDocuments: CandidatDocumentModel[], consultantDocuments: ConsultantDocumentModel[] }>(`${API_URL_ESPACE_AVENTURIER}/add-document`, formData)
}

export function setDocumentEmbauche(file: File, reference: string) {
    const formData = new FormData();
    formData.append('fichier', file)
    formData.append('reference', reference)

    return axios.post<{ candidatDocuments: CandidatDocumentModel[], consultantDocuments: ConsultantDocumentModel[] }>(`${API_URL_ESPACE_AVENTURIER}/set-document`, formData)
}

export function removeDocumentEmbauche(reference: string) {
    return axios.post<{ candidatDocuments: CandidatDocumentModel[], consultantDocuments: ConsultantDocumentModel[] }>(`${API_URL_ESPACE_AVENTURIER}/remove-document`, {
        reference
    })
}

export function setPhotoProfil(file: File) {
    const formData = new FormData();
    formData.append('fichier', file)

    return axios.post<{ id: string }>(`${API_URL_ESPACE_AVENTURIER}/set-photo-profil`, formData)
}

export function removePhotoProfil() {
    return axios.post(`${API_URL_ESPACE_AVENTURIER}/remove-photo-profil`)
}



